.navbar-brand {
  display: flex;
  align-items: center;

}
.navbar-brand>img {
  padding-right: 10px;
  width: 60px;
}

.money{
  padding:5px;
  font-size: 20px;
  letter-spacing: 0.1ch;
}

.padme{
  padding:15px;
}
